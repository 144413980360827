.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-header{
    display: flex;
    gap: 4rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

/*Mobile veiw css*/
@media screen and (max-width:768px) {

    .programs-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }
    .programs-categories{
        flex-direction: column;
    }
}