.Join{
    display: flex;
    padding: 0 2rem;
    gap:10rem
}
.l-join{
color: white;
font-size: 3rem;
font-weight: bold;
text-transform: uppercase;
position: relative;
}
.l-join>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 10px;
    margin-top: -10px;
}
.l-join>div{
    display: flex;
    gap: 1rem;
}
.r-join{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display: flex;
    gap: 3rem;
    background-color: var(--gray);
    padding: 1rem 2rem;
}
.email-container>input{
background-color: transparent;
border: none;
outline: none;
color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
    background-color: transparent;
}
.btn-j{
    background-color: var(--orange);
    color: white;
}
/*Mobile veiw css*/
@media screen and (max-width:768px) {
    .Join{
        flex-direction: column;
        gap: 1rem;
    }
    .l-join{
        flex-direction: column;
        font-size: x-large;
        
    }
    .r-join{
        padding: 2rem;
    }
}