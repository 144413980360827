.reasons{
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}
.l-reasons{
flex: 1 1;
display: grid;
grid-template-columns: repeat(3 , 1fr);
gap: 1rem;
grid-auto-rows: 1fr;
}
.l-reasons>img{
    object-fit: cover;
}
.l-reasons>:nth-child(1){
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
}
.l-reasons>:nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}
.l-reasons>:nth-child(3){
    width: 14rem;
    grid-row: 2;
    grid-column: 2/3;
}
.l-reasons>:nth-child(4){
    width: 10rem;
    grid-row: 2;
    grid-column: 3/4;
    height: 11.2rem;
}
.r-reasons{
    flex: 1 1;
    text-transform: uppercase;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
.r-reasons>span{
font-weight: bold;
color: var(--orange);
}
.r-reasons>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.details-r>div{
    display: flex;
    font-size: 1rem;
    gap:1rem;
}
.details-r>div>img{
width: 2rem;
height: 2rem;
}
.partners{
    display: flex;
    gap: 1rem;
}
.partners>img{
    width: 2.5rem;
}
/*Mobile veiw css*/
@media screen and (max-width:768px) {
    .reasons{
        flex-direction: column;
    }
    .l-reasons{
        grid-auto-rows: auto;
        overflow: hidden;
    }
    .l-reasons>:nth-child(1){
        width: 6rem;
        height: 17rem;
    }
    .l-reasons>:nth-child(2){
        width: 9rem;
        height: 10rem;
    }
    .l-reasons>:nth-child(3){
        width: 4rem;
        height: 6rem;
      
    }
    .l-reasons>:nth-child(4){
        width: 4rem;
        height: 6rem;
    }
}