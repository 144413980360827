.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    color: white;
    gap:2rem;
    padding:1.5rem;
    width: 15rem;
}
.plans>:nth-of-type(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plans>:nth-child(2)>svg{
fill: white;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 0.8rem;

}
.features{
    display: flex;
    flex-direction: column;
    gap:1rem;
}
.feature{
    display: flex;
    gap: 1rem;
    align-items: center;
}
.feature>img{
    width: 1rem;
}
.plans>:nth-child(2)>.btn{
    color:var(--orange);
}
.plan-blur-l{
    width: 32rem;
    height: 22rem;
    top:6rem;
    left: 0;
}
.plan-blur-r{
    width: 32rem;
    height: 22rem;
    top:10rem;
    right: 0;
}
/*Mobile veiw css*/
@media screen and (max-width:768px) {
    .plans{
flex-direction: column;
    }
    .plans>:nth-of-type(2){
       transform: none;
    }
}