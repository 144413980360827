.testimonials {
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}

.l-testimonial {
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}

.l-testimonial>:nth-child(1) {
    color: var(--orange);
    font-weight: bold;
}

.l-testimonial>:nth-child(2),
.l-testimonial>:nth-child(3) {
    font-weight: bold;
    font-size: 3rem;
}

.l-testimonial>:nth-child(4) {
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}

.r-testimonial {
    flex: 1;
    position: relative;
}

.r-testimonial>img {
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}

.r-testimonial>:nth-child(1) {
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid var(--orange);
    background-color: transparent;
    right: 9rem;
    top: 0.9rem;
}

.r-testimonial>:nth-child(2) {
    position: absolute;
    width: 17rem;
    height: 19rem;

    background: var(--planCard);
    right: 7rem;
    top: 4rem;
}

.arrows {
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;
}

.arrows>img {
    width: 1.5rem;
    cursor: pointer;
}

/*Mobile veiw css*/
@media screen and (max-width:768px) {
    .testimonials {
        flex-direction: column;
    }

    .l-testimonial>:nth-child(2),
    .l-testimonial>:nth-child(3) {

        font-size: xx-large;
    }
    .r-testimonial{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
    .r-testimonial>div{
        position: relative;
        display: none;
    }
    .r-testimonial>img{
        top:0;
        right: 0;
        position: relative;
        align-self: center;
    }
    .r-testimonial>:last-child{
        display: block;
        left: 0;
        bottom: 0;
        
    }
}